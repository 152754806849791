import React, {useRef} from 'react';
import tw, {styled} from 'twin.macro';
import data from '../../../config/site_data.json';
import {Container, Section} from '../../Grid';
import {Heading} from '../../Typography';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {v4 as uuidv4} from 'uuid';
import {Button} from '../../Button';
import BookNowController from '../../BookNowController';
import Glide from 'react-glidejs';
import useWindowSize from '../../../hooks/useWindowSize';
import NavComponent from '../home/ourwork/NavComponent';

const StyledButton = styled(Button)`
  ${tw`w-full md:w-max`}
  border-radius: 10px;
  height: 52px !important;
  margin-bottom: 50px;
  margin-top: 20px;

  @media ${props => props.theme.screens.md} {
    height: 68px;
    font-size: 18px;
  }
`;

const SecondaryButton = styled(Button)`
  background-color: white;
  color: black;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid rgba(108, 108, 108, 0.4);
  border-radius: 10px;
  height: 52px !important;
`;

export const StyledHeading = styled(Heading)`
  ${tw`text-extra`}
  font-size: 19px;
  line-height: 31px;
  color: black;
  margin-bottom: 26px;

  @media ${props => props.theme.screens.md} {
    font-size: 30px;
    line-height: 42px;
  }
`;

const Wrapper = styled(Section)``;

export const Description = styled.p`
  ${tw`font-medium text-extra mt-4`}
  margin-bottom: ${props => props.marginBottom};
  text-align: ${props => props.textAlign};
  color: black;
  width: 100%;
  a {
    color: #039259;
    text-decoration: underline;
  }

  font-size: 15px;
  line-height: 24px;
  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 31px;
  }

  @media ${props => props.theme.screens.lg} {
    width: ${props => props.width};
  }
`;

export const List = styled.ul`
  padding-left: 20px;
  list-style: disc;
  margin-bottom: ${props => props.marginBottom};
  margin-top: ${props => props.marginTop};

  a {
    color: #009259;
    text-decoration: underline;
  }

  li {
    ${tw`my-6`}
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: black;
    padding-left: 20px;

    @media ${props => props.theme.screens.lg} {
      font-size: 19px;
    }
  }
`;

const Card = styled.div`
  background: #f8f9f8;
  border-radius: 16px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;

  @media ${props => props.theme.screens.md} {
    padding: 50px 50px 25px 50px;
    height: 100%;
  }
`;

const OrderedList = styled.ol`
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 25px;

  li {
    font-size: 15px;
    font-weight: 500;
    color: black;
    margin-bottom: 8px;
    line-height: 24px;

    @media ${props => props.theme.screens.md} {
      font-size: 19px;
      line-height: 31px;
    }
  }
`;

export const CardComponent = ({children, heading, items}) => {
  const {t} = useTranslation();

  return (
    <Card>
      <div>
        <StyledHeading>{heading}</StyledHeading>
        {children && children}
        {!children && (
          <OrderedList>
            {items.map((item: any) => (
              <li key={uuidv4()}>{item}</li>
            ))}
          </OrderedList>
        )}
      </div>
      {!children && (
        <BookNowController>
          <StyledButton>{t('office_cleaning_book')}</StyledButton>
        </BookNowController>
      )}
    </Card>
  );
};

const CleaningTypes = () => {
  const {t} = useTranslation();
  const {heading} = data.office_cleaning.included;
  const {button_text} = data.office_cleaning.description;
  const items = t(`office_cleaning_included_list`).split(';');
  const createList = (content: string) => content.split(';');
  const size = useWindowSize();
  const CUSTOM_BREAKPOINT = 976;
  const isTablet = size?.width <= CUSTOM_BREAKPOINT;
  const ref = useRef({
    slider: React.createRef<HTMLElement>(),
    header: React.createRef<HTMLElement>(),
  });

  if (isTablet) {
    const handleSliderButton = (event: any) => {
      ref!.current!.slider!.current!.go(event.target.dataset.slideDirection);
      ref!.current!.header!.current!.changeIndex();
    };

    const handleSliderUpdate = () => {
      ref!.current!.header!.current!.changeIndex();
    };

    return (
      <>
        <div className="flex-1 w-full lg:w-1/2 p-4 items-center justify-center h-100">
          <Description
            dangerouslySetInnerHTML={{
              __html: t(`office_cleaning_else_description`),
            }}
          />
        </div>
        <NavComponent
          className="flex my-4 justify-center"
          ref={ref.current.header}
          items={[{node: {id: 1}}, {node: {id: 2}}, {node: {id: 3}}]}
          handleSliderButton={handleSliderButton}
          sliderRef={ref.current.slider}
        />
        <Glide
          ref={ref.current.slider}
          type="slider"
          perView={3}
          startAt={0}
          focusAt={0}
          bound={true}
          swipeThreshold={false}
          dragThreshold={false}
          hideArrows
          autoplay={4000}
          onRun={handleSliderUpdate}
          breakpoints={{
            768: {
              autoplay: false,
              type: 'slider',
              perView: 1,
              focusAt: 'center',
            },
            976: {
              focusAt: 0,
              perView: 1,
              gap: '20px',
              peek: {
                before: 30,
                after: 250,
              },
            },
          }}
        >
          <div className="flex-1 w-full lg:w-1/2 p-4">
            <CardComponent heading={t(heading)} items={items} />
          </div>
          <div className="flex-1 w-full lg:w-1/2 p-4 items-center justify-center h-100">
            <CardComponent
              heading={t('office_cleaning_regular_heading')}
              items={createList(t(`office_cleaning_regular_list`))}
              btnText={t()}
            />
          </div>
          <div className="flex-1 w-full lg:w-1/2 p-4">
            <CardComponent
              heading={t('office_cleaning_deep_heading')}
              items={createList(t(`office_cleaning_deep_list`))}
            />
          </div>
        </Glide>
        <Container>
          <Description
            marginBottom="35px"
            width="840px"
            dangerouslySetInnerHTML={{
              __html: t(`office_cleaning_services_description`),
            }}
          />
          <BookNowController>
            <SecondaryButton className="mb-10">
              {t('office_cleaning_contact_us')}
            </SecondaryButton>
          </BookNowController>
          <StyledHeading>{t(`office_cleaning_prices_heading`)}</StyledHeading>
          <Description
            marginBottom="70px"
            width="840px"
            dangerouslySetInnerHTML={{
              __html: t(`office_cleaning_prices_description`),
            }}
          />
          <StyledHeading>{t(`office_cleaning_else_heading`)}</StyledHeading>
          <Description
            marginBottom="70px"
            width="840px"
            dangerouslySetInnerHTML={{
              __html: t(`office_cleaning_else_description`),
            }}
          />
        </Container>
      </>
    );
  }

  return (
    <Wrapper>
      <Container>
        <div className="flex flex-wrap">
          <div className="flex flex-1 w-full lg:w-1/2 p-4 items-center justify-center h-100">
            <Description
              dangerouslySetInnerHTML={{
                __html: t(`office_cleaning_else_description`),
              }}
            />
          </div>
          <div className="flex-1 w-full lg:w-1/2 p-4">
            <CardComponent heading={t(heading)} items={items} />
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="flex-1 w-full lg:w-1/2 p-4 items-center justify-center h-100">
            <CardComponent
              heading={t('office_cleaning_regular_heading')}
              items={createList(t(`office_cleaning_regular_list`))}
              btnText={t()}
            />
          </div>
          <div className="flex-1 w-full lg:w-1/2 p-4">
            <CardComponent
              heading={t('office_cleaning_deep_heading')}
              items={createList(t(`office_cleaning_deep_list`))}
            />
          </div>
        </div>
        <StyledHeading className="mt-10">
          {t(`office_cleaning_services_heading`)}
        </StyledHeading>
        <Description
          marginBottom="35px"
          width="840px"
          dangerouslySetInnerHTML={{
            __html: t(`office_cleaning_services_description`),
          }}
        />
        <BookNowController>
          <SecondaryButton className="mb-10">
            {t('office_cleaning_contact_us')}
          </SecondaryButton>
        </BookNowController>
        <StyledHeading>{t(`office_cleaning_prices_heading`)}</StyledHeading>
        <Description
          marginBottom="70px"
          width="840px"
          dangerouslySetInnerHTML={{
            __html: t(`office_cleaning_prices_description`),
          }}
        />
        <StyledHeading>{t(`office_cleaning_else_heading`)}</StyledHeading>
        <Description
          marginBottom="70px"
          width="840px"
          dangerouslySetInnerHTML={{
            __html: t(`office_cleaning_else_description`),
          }}
        />
      </Container>
    </Wrapper>
  );
};

export default CleaningTypes;
